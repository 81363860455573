import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ArrowLeft, Send,ChevronRight } from 'lucide-react';
import axios from 'axios';
import './AppCSS/App.css';
import MonetizationFlow from './payment';
import TermsAndConditions from './TermsAndConditions';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { GoogleGenerativeAI } from '@google/generative-ai';


<link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap" rel="stylesheet"></link>

const characters = [
  { id: 1, name: 'Tony Stark', image: '/images/tony-stark.png', description: 'Genius billionaire playboy philanthropist' },
  { id: 2, name: 'Gojo Satoru', image: '/images/gojo-satoru.png', description: 'I alone am the honored one' },
  { id: 3, name: 'Naruto Uzumaki', image: '/images/naruto-uzumaki.png', description: "Believe it! I'm gonna be Hokage!" },
  { id: 4, name: 'Elon Musk', image: '/images/elon-musk.png', description: 'Nuke Mars!' },
  { id: 5, name: 'Deadpool', image: '/images/deadpool.png', description: 'Merc with a Mouth' },
  { id: 6, name: 'CarryMinati', image: '/images/carryminati.png', description: 'To Chaliye Shuru Karte Hai!' },
  { id: 7, name: 'Shin Chan', image: '/images/shin-chan.png', description: 'Mai Itna Bhi Khas Nhi!' },
  { id: 8, name: 'Triggered Insaan', image: '/images/triggered-insaan.png', description: 'Yeah its me!' },
  { id: 9, name: 'Ben 10', image: '/images/ben-10.png', description: "It's hero time!" },
  { id: 10, name: 'Goku', image: '/images/goku.png', description: 'Kamehameha!' },
  { id: 11, name: 'Doraemon', image: '/images/doraemon.png', description: 'I have got a gadget for that!' },
  { id: 12, name: 'Monkey D. Luffy', image: '/images/luffy.png', description: "I'm gonna be King of the Pirates!" },
  { id: 13, name: 'Lelouch vi Britannia', image: '/images/lelouch.png', description: 'Obey me Subjects!' },
  { id: 14, name: 'Light Yagami', image: '/images/light.png', description: 'I will create a new world.' },
  { id: 15, name: 'Kakashi Hatake', image: '/images/kakashi.png', description: "Sorry I'm late, I got lost on the path of life" },
  { id: 16, name: 'Zoro', image: '/images/zoro.png', description:  "I'm going to be the world's greatest swordsman!" },
  { id: 17, name: 'Itachi Uchiha', image: '/images/itachi.png', description: "You don't have enough hatred."},
  { id: 18, name: 'Tanjiro Kamado', image: '/images/tanjiro.png', description: "I smell the scent of a demon!" },
  { id: 19, name: 'Vegeta', image: '/images/vegeta.png', description: "I am the Prince of all Saiyans!" },
  { id: 20, name: 'Thor', image: '/images/thor.png', description: "For Asgard!" },
  { id: 21, name: 'Madara Uchiha', image: '/images/madara.png', description: 'Wake Up To Reality' },
  { id: 22, name: 'Ninja Hattori', image: '/images/hattori.png', description: 'Ding Ding!' },


];

const stories = [
  { id: 1, title: 'Alone in the Haunted Mansion', image: '/images/haunted-mansion.jpg' },
  { id: 2, title: 'Alone on a Distant Planet', image: '/images/distant-planet.jpg' },
  { id: 3, title: 'On Mission with Naruto', image: '/images/naruto-mission.jpg' },
  { id: 4, title: 'Time Travel Paradox', image: '/images/time-travel.jpg' },
  { id: 5, title: 'Doctor Doom Destroying the Multiverse', image: '/images/multiverse.jpg' },
  { id: 6, title: 'You are A Pokemon Master', image: '/images/pokemon.jpg' },
  { id: 7, title: 'AI is Ruling the World', image: '/images/ai-rebellion.jpg' },
  { id: 8, title: 'You Are The Richest Person in The World', image: '/images/richest.png' },
  { id: 9, title: 'Solving the Murder Mystery', image: '/images/murder-mystery.png' },
  { id: 10, title: 'Alone in the Jungle', image: '/images/jungle.jpg' },
  { id: 11, title: 'Aliens vs Humans', image: '/images/avh.jpg' },
  { id: 12, title: 'You are Nobita in Doraemon Universe', image: '/images/n_in_d_u.png' },
  { id: 13, title: 'Training with Goku', image: '/images/gokutraining.png' },
  { id: 14, title: 'You Are Ironman Fighting Thanos', image: '/images/I_fight_thanos.png' },
  { id: 15, title: 'Mind Battle With Smartest Person', image: '/images/mind_battle.jpg' },
  { id: 16, title: 'You are a Akatsuki Member', image: '/images/akatsuki_member.png' },
  { id: 17, title: 'You are the Last Person on Earth', image: '/images/lastperson.jpg' },
  { id: 18, title: 'Space Battle', image: '/images/spacebattle.jpg' },
  { id: 19, title: 'Goku vs Vegeta', image: '/images/gokuvvegeta.jpg' },
  { id: 20, title: 'You are Batman', image: '/images/iambatman.jpg' },
  
];

// Initialize Gemini
const genAI = new GoogleGenerativeAI('AIzaSyBICVsz01dnvYdxrnWGdgXn1MvLJ4GMZpc');
const model = genAI.getGenerativeModel({ model: "gemini-pro" });

const callGeminiAPI = async (prompt) => {
  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    return response.text();
  } catch (error) {
    console.error('Gemini API Error:', error);
    throw new Error('Failed to get AI response');
  }
};

const generatePrompt = (isInitial, userInput = '', type = 'character', character = null) => {
  if (type === 'character' && character) {
    if (isInitial) {
      return `You are ${character.name}. Stay in character at all times. Introduce yourself as ${character.name} with your personality traits and characteristics. Make it engaging and conversational.`;
    }
    return `You are ${character.name}. Stay in character at all times. Respond to: "${userInput}" as ${character.name} would, maintaining character traits and personality.`;
  } else {
    if (isInitial) {
      return `Start an interactive story. Make it engaging and ask the user for a choice at the end. The story should be about 3-4 paragraphs long.`;
    }
    return `Continue the story based on the user's choice: "${userInput}". Keep it engaging and ask for another choice at the end. The response should be about 3-4 paragraphs long.`;
  }
};

const CharacterAndStoryUI = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [chatType, setChatType] = useState(null);
  const [showAllCharacters, setShowAllCharacters] = useState(false);
  const [showAllStories, setShowAllStories] = useState(false);

  const handleSelect = useCallback((item, type) => {
    setSelectedItem(item);
    setChatType(type);
    setShowChat(true);
    setShowAllCharacters(false);
    setShowAllStories(false);
  }, []);

  const handleShowAllCharacters = () => {
    setShowAllCharacters(true);
  };

  const handleShowAllStories = () => {
    setShowAllStories(true);
  };

  const handleBack = useCallback(() => {
    setShowAllCharacters(false);
    setShowAllStories(false);
    setShowChat(false);
    setSelectedItem(null);
  }, []);

  if (showChat) {
    return chatType === 'character' ? (
      <ChatInterface item={selectedItem} type={chatType} onBack={handleBack} />
    ) : (
      <StoryInterface item={selectedItem} onBack={handleBack} />
    );
  }

  if (showAllCharacters) {
    return <AllCharactersPage characters={characters} onSelect={handleSelect} onBack={handleBack} />;
  }

  if (showAllStories) {
    return <AllStoriesPage stories={stories} onSelect={handleSelect} onBack={handleBack} />;
  }

  return (
    <div className="container">
      <MonetizationFlow />
      

      {/* Character section */}
      <div className="section-container">
        <h2 className="animate-bounce">Characters</h2>
        <div className="character-grid">
          {characters.slice(0, 10).map((character) => (
            <CharacterCard key={character.id} character={character} onSelect={handleSelect} />
          ))}
        </div>
        <button onClick={handleShowAllCharacters} className="show-all-button">
          <span>Show All Characters</span>
          <ChevronRight size={20} />
        </button>
      </div>

      {/* Story section */}
      <div className="section-container">
        <h2 className="animate-bounce">You Are The Main Character</h2>
        <div className="story-grid">
          {stories.slice(0, 10).map((story) => (
            <StoryCard key={story.id} story={story} onSelect={handleSelect} />
          ))}
        </div>
        <button onClick={handleShowAllStories} className="show-all-button">
          <span>Show All Stories</span>
          <ChevronRight size={20} />
        </button>
      </div>
     
    </div>
  );
};

const AllCharactersPage = ({ characters, onSelect, onBack }) => (
  <div className="container">
    <button onClick={onBack} className="back-button">
      <ArrowLeft size={24} />
    </button>
    <h2>All Characters</h2>
    <div className="character-grid">
      {characters.map((character) => (
        <CharacterCard key={character.id} character={character} onSelect={onSelect} />
      ))}
    </div>
  </div>
);

const AllStoriesPage = ({ stories, onSelect, onBack }) => (
  <div className="container">
    <button onClick={onBack} className="back-button">
      <ArrowLeft size={24} />
    </button>
    <h2>All Stories</h2>
    <div className="story-grid">
      {stories.map((story) => (
        <StoryCard key={story.id} story={story} onSelect={onSelect} />
      ))}
    </div>
  </div>
);

const CharacterCard = ({ character, onSelect }) => (
  <div 
    className="character-card"
    onClick={() => onSelect(character, 'character')}
  >
    <div className="character-avatar">
      <img src={character.image} alt={character.name} />
    </div>
    <div className="character-info">
      <h3 className="character-name">{character.name}</h3>
      <p className="character-description">{character.description}</p>
    </div>
  </div>
);

const StoryCard = ({ story, onSelect }) => (
  <div 
    className="story-card"
    onClick={() => onSelect(story, 'story')}
  >
    <div className="story-image">
      <img src={story.image} alt={story.title} />
    </div>
    <div className="story-info">
      <h3 className="story-title">{story.title}</h3>
    </div>
  </div>
);


const ChatInterface = ({ item, type, onBack }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const isInitialRender = useRef(true);

  const handleSend = async () => {
    if (input.trim()) {
      setMessages(prevMessages => [...prevMessages, { 
        id: Date.now(), 
        text: input, 
        sender: 'user' 
      }]);
      setInput('');
      setIsTyping(true);
      setError(null);

      try {
        const prompt = generatePrompt(false, input, 'character', item);
        const aiResponse = await callGeminiAPI(prompt);
        
        setMessages(prevMessages => [...prevMessages, {
          id: Date.now(),
          text: aiResponse,
          sender: 'ai'
        }]);
      } catch (error) {
        console.error('Error:', error);
        setError('Sorry, I encountered an error. Please try again.');
      } finally {
        setIsTyping(false);
      }
    }
  };

  useEffect(() => {
    if (isInitialRender.current) {
      const initializeChat = async () => {
        try {
          setIsTyping(true);
          const prompt = generatePrompt(true, '', 'character', item);
          const aiResponse = await callGeminiAPI(prompt);
          setMessages([{ 
            id: Date.now(), 
            text: aiResponse, 
            sender: 'ai' 
          }]);
        } catch (error) {
          console.error('Error initializing chat:', error);
          setError('Failed to start conversation. Please try again.');
        } finally {
          setIsTyping(false);
        }
      };

      initializeChat();
      isInitialRender.current = false;
    }
  }, [item]);

  return (
    <div className="chat-interface">
      <MonetizationFlow inChatInterface={true} />
      <div className="chat-header">
        <button onClick={onBack} className="back-button" style={{ position: 'absolute', left: '0.5rem' }}>
          <ArrowLeft size={24} />
        </button>
        <div className="chat-avatar" style={{ marginLeft: '2.5rem' }}>
          <img src={item.image} alt={item.name || item.title} />
        </div>
        <h2 className="chat-title">{type === 'character' ? item.name : item.title}</h2>
      </div>
      <div className="chat-messages">
        {error && <div className="error-message">{error}</div>}
        {messages.map((message) => (
          <div
            key={message.id}
            className={`chat-message ${message.sender === 'user' ? 'user-message' : 'ai-message'}`}
          >
            {message.text}
          </div>
        ))}
        {isTyping && (
          <div className="chat-message ai-message typing-indicator">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        )}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          placeholder="Type your message..."
        />
        <button onClick={handleSend} className="send-button">
          <Send size={24} />
        </button>
      </div>
      

    </div>
  );
};


const StoryInterface = ({ item, onBack }) => {
  const [storySegments, setStorySegments] = useState([]);
  const [userChoice, setUserChoice] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const storyContentRef = useRef(null);
  const isInitialRender = useRef(true);

  const handleAIResponse = useCallback(async (isInitial = false, userChoice = '') => {
    try {
      setIsTyping(true);
      setError(null);

      const prompt = generatePrompt(isInitial, userChoice, 'story');
      const aiResponse = await callGeminiAPI(prompt);
      
      setStorySegments(prevSegments => [
        ...prevSegments,
        { id: Date.now(), text: aiResponse, type: 'ai' }
      ]);
      
      setShowOptions(true);
    } catch (error) {
      console.error('Error generating story:', error);
      setError('Sorry, there was an error generating the story. Please try again.');
    } finally {
      setIsTyping(false);
    }
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      handleAIResponse(true);
      isInitialRender.current = false;
    }
  }, [handleAIResponse]);

  const handleContinue = (choice) => {
    if (choice) {
      setStorySegments(prevSegments => [
        ...prevSegments,
        { id: Date.now(), text: choice, type: 'user' }
      ]);
      handleAIResponse(false, choice);
      setUserChoice('');
      setShowOptions(false);
    }
  };

  const handleOptionSelect = (option) => {
    setUserChoice(option);
    handleContinue(option);
  };

  useEffect(() => {
    if (storyContentRef.current) {
      storyContentRef.current.scrollTop = storyContentRef.current.scrollHeight;
    }
  }, [storySegments]);

  return (
    <div className="story-interface">
      <MonetizationFlow inChatInterface={true} />
      <div className="story-header">
        <button onClick={onBack} className="back-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
          </svg>
        </button>
        <h2 className="story-title">{item.title}</h2>
      </div>
      <div className="story-content" ref={storyContentRef}>
        {storySegments.map((segment) => (
          <p key={segment.id} className={`story-paragraph ${segment.type === 'user' ? 'user-choice' : ''}`}>
            {segment.type === 'user' ? `Your choice: ${segment.text}` : segment.text}
          </p>
        ))}
        {isTyping && (
          <div className="typing-indicator">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        )}
        {error && <div className="error-message">{error}</div>}
      </div>
      <div className="story-input">
        {showOptions ? (
          <div className="story-options">
            <button onClick={() => handleOptionSelect('Option 1')}>Option 1</button>
            <button onClick={() => handleOptionSelect('Option 2')}>Option 2</button>
            <button onClick={() => setShowOptions(false)}>Do something else</button>
          </div>
        ) : (
          <>
            <input
              type="text"
              value={userChoice}
              onChange={(e) => setUserChoice(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleContinue(userChoice)}
              placeholder="Type your choice..."
            />
            <button onClick={() => handleContinue(userChoice)} className="continue-button">
              Continue
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/monetization" element={<MonetizationFlow />} />
        <Route path="/" element={<CharacterAndStoryUI />} />
      </Routes>
    </Router>
  );
};

export default App; 
